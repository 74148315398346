import React from "react";
import { graphql } from "gatsby";

import { Base } from "../components/layout";
import PostContent from "../components/page-contents/PostContent";
import SEO from "../components/seo";

type BlogPostPageProps = {
  data: any;
  location: any;
}

export const Head: React.FC<BlogPostPageProps> = ({data}) => <SEO title={`Syrona Health | News | ${data.prismicNewsItem.data.title.text}`} />

const NewsItemPage: React.FC<BlogPostPageProps> = ({ data, location }) => <Base><PostContent data={data.prismicNewsItem.data} location={location} /></Base>

export default NewsItemPage;

export const query = graphql`
  query blogPost($uid: String!) {
    prismicNewsItem(uid: { eq: $uid }) {
      data {
        title {
          text
        }
        author
        date
        content {
          richText
          html
        }
        cover {
          url
        }
      }
    }
  }
`

